<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="back" class="back" @click="back">返回</div>
    <div slot="content">
      <!-- 搜索和新增 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="数据名称" prop="vname">
            <a-input
              v-model="queryParams.vname"
              placeholder="请输入数据名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['version:parameter:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['version:parameter:query']"
            >
              重置
            </a-button>
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['version:parameter:add']"
            >新增</a-button
          >
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        class="mt20"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['version:parameter:edit']"
            >编辑</a
          >
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['version:parameter:remove']"
            >删除</a
          >
        </span>
      </a-table>
      <!--分页-->
      <div class="d-f mt20">
        <a-pagination
          v-model="queryParams.page"
          :pageSize="queryParams.limit"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          style="margin-left: auto"
        />
      </div>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="700px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="数据名称" prop="vname">
                <a-input v-model="form.vname" placeholder="请输入数据名称" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="数据值" prop="value">
                <a-input v-model="form.value" placeholder="请输入数据值" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="排序" prop="orderNum">
                <a-input-number
                  v-model="form.orderNum"
                  placeholder="请输入排序"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getParameterDataByParamName,
  parameterDataAddOrUpdate,
  parameterDataRemove,
} from "@/api/version/parameter.js";
const columns = [
  {
    title: "数据名称",
    dataIndex: "vname",
    align: "center",
  },
  {
    title: "数据值",
    dataIndex: "value",
    align: "center",
  },
  {
    title: "数据排序",
    dataIndex: "orderNum",
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "ParameterData",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
        //安卓参数的name
        paramName: null,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        vname: [
          {
            required: true,
            message: "请输入数据名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        value: [
          {
            required: true,
            message: "请输入数据值",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        orderNum: [
          {
            type: "number",
            required: true,
            message: "请输入排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
    };
  },
  methods: {
    //返回上一级
    back() {
      this.$router.go(-1);
    },
    //获取参数标签列表
    getParameterDataList() {
      getParameterDataByParamName(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.queryParams.paramName = this.$route.query.paramName;
      this.queryParams.paramId = this.$route.query.paramId;
      this.getParameterDataList();
    },
    //搜索重置
    resetQuery() {
      this.$refs.queryForm.resetFields();
      this.queryParams.page = 1;
      this.queryParams.paramName = this.$route.query.paramName;
      this.queryParams.paramId = this.$route.query.paramId;
      this.getParameterDataList();
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.form = {};
      this.form.paramName = this.$route.query.paramName;
      this.form.paramId = this.$route.query.paramId;
      this.visible = true;
      this.title = "新增参数数据";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.form = {};
      this.form = row;
      this.visible = true;
      this.title = "修改参数数据";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          parameterDataRemove(row.id).then((res) => {
            this.$message.success("删除成功");
            this.getParameterDataList();
          });
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          parameterDataAddOrUpdate(this.form).then((res) => {
            //修改
            if (this.form.id) {
              this.$message.success("修改成功");
            }
            //新增
            else {
              this.$message.success("新增成功");
            }
            this.visible = false;
            this.getParameterDataList();
          });
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.queryParams.page = pageNumber;
      this.getParameterDataList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
  },
  created() {
    this.queryParams.paramName = this.$route.query.paramName;
    this.queryParams.paramId = this.$route.query.paramId;
    this.getParameterDataList();
  },
};
</script>

<style scoped lang="less">
.back {
  color: @primary-color;
  font-weight: normal;
  cursor: pointer;
}
</style>
