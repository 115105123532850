import request from '@/utils/request'

//获取参数列表
export function getParameterList(params) {
    return request.get('/api-device/device/android-param-type/list', params)
}

//新增或修改参数
export function parameterAddOrUpdate(params) {
    return request.post('/api-device/device/android-param-type/saveOrUpdate', params)
}

//删除参数
export function parameterRemove(id) {
    return request.remove('/api-device/device/android-param-type/delete?id='+id)
}



//根据参数名称获取参数信息
export function getParameterDataByParamName(params) {
    return request.get('/api-device/device/android-param-value/list',params)
}

//新增或修改参数数据
export function parameterDataAddOrUpdate(params) {
    return request.post('/api-device/device/android-param-value/saveOrUpdate', params)
}

//删除参数数据
export function parameterDataRemove(id) {
    return request.remove('/api-device/device/android-param-value/delete?id='+id)
}